<template>
  <div>
    <b-container fluid class="content staff">
      <b-alert
        :show="countdown"
        dismissible
        :variant="variant"
        @dismissed="countdown = 0"
        @dismiss-count-down="close_alert"
        class="show_alert"
      >
        {{ dismessage }}
      </b-alert>
      <el-tabs v-model="tab_name" @tab-click="table_change">
        <el-tab-pane :label="$t('Staff')" name="Staff">
          <b-card>
            <b-row v-if="currpage == 'list'">
              <b-col>
                <p v-if="userlist">
                  <el-table
                    stripe
                    class="table-responsive table"
                    header-row-class-name="thead-light"
                    :data="userlist"
                    @row-click="row_clicked_event"
                  >
                    <el-table-column
                      :label="$t('Username')"
                      prop="UserName"
                    ></el-table-column>
                    <el-table-column :label="$t('Store')" prop="Store">
                      <template v-slot="{ row }">
                        <b-button
                          v-for="lid in row.Store"
                          :key="lid"
                          variant="outline-success"
                          class="mr-0 ml-0 mt-0 mb-0 pr-0 pl-0 pt-0 pb-0"
                          >{{ lid }}</b-button
                        >
                      </template>
                    </el-table-column>
                    <el-table-column :label="$t('Roles')" prop="UserRole">
                    </el-table-column>
                    <el-table-column :label="$t('Status')" align="center">
                      <template v-slot:header>
                        {{$t('Status')}}
                        <i @click="add_user" class="el-icon-circle-plus float-right mt-1 mr-6" style="color: #409eff" v-if="hasUtilityAuth('StaffEdit')"></i>
                      </template>
                      <template v-slot="{ row }">
                        <b-form-checkbox
                          class="form-checkbox"
                          v-model="row.Status"
                          value="1"
                          unchecked-value="0"
                          switch
                          :style="hasUtilityAuth('StaffEdit') ? {marginLeft: '-3.8rem'} : {marginLeft: '1.6rem'}"
                          @change="change_row_property(row, 'Status')"
                          :disabled="!hasUtilityAuth('StaffEdit')"
                        />
                      </template>
                    </el-table-column>
                  </el-table>
                </p>
                <p class="p-3" v-else>
                  {{ $t("No Records") }}
                </p>
              </b-col>
            </b-row>
            <b-row v-if="currpage == 'edit'">
              <b-col>
                <b-card no-body>
                  <b-card-body>
                    <b-form>
                      <el-row :gutter="10">
                        <el-col :span="24">
                          <b-form-group :label="$t('Store')">
                            <b-form-checkbox-group
                              id="storelist1"
                              v-model="cur_user.Store"
                              inline="true"
                              :options="locationIDs"
                            ></b-form-checkbox-group>
                          </b-form-group>
                        </el-col>
                      </el-row>
                      <el-row :gutter="10">
                        <el-col :span="6">
                          <b-form-group :label="$t('Username')">
                            <b-form-input
                              placeholder="UserName"
                              v-model="cur_user.UserName"
                            ></b-form-input>
                          </b-form-group>
                        </el-col>
                        <el-col :span="6">
                          <b-form-group :label="$t('Password')">
                            <b-form-input
                              placeholder="Password"
                              v-model="cur_user.Password"
                            ></b-form-input>
                          </b-form-group>
                        </el-col>
                        <el-col :span="6">
                          <b-form-group :label="$t('Gerenate')">
                            <b-input-group :append="$t('Length')">
                              <el-button
                                type="primary"
                                @click="gerenate_pw"
                                class="pr-3 mr-3"
                                size="sm"
                                >{{ $t("Gerenate Password") }}</el-button
                              >
                              <b-form-input
                                placeholder="Length"
                                v-model="pw_length"
                              ></b-form-input>
                            </b-input-group>
                          </b-form-group>
                        </el-col>
                        <el-col :span="6">
                          <b-form-group :label="$t('Setting Group')">
                            <b-form-select
                              v-model="cur_user.UserRole"
                              @change="changed_roles"
                            >
                              <b-form-select-option :value="null"></b-form-select-option>
                              <b-form-select-option v-for="group in grouplist" :key="group.IDn" :value="group.Name">{{ group.Name }}</b-form-select-option>
                            </b-form-select>
                          </b-form-group>
                        </el-col>
                      </el-row>
                      <el-row :gutter="10">
                        <el-col :span="6">
                          <b-form-group :label="'POS ' + $t('Language')">
                            <b-form-radio-group v-model="cur_user.UILanguage">
                              <b-form-radio value="0">English</b-form-radio>
                              <b-form-radio value="1">简体</b-form-radio>
                              <b-form-radio value="2">繁体</b-form-radio>
                            </b-form-radio-group>
                          </b-form-group>
                        </el-col>
                        <el-col :span="6">
                          <b-form-group :label="$t('Menu Language')">
                            <b-form-radio-group
                              v-model="cur_user.ProductLanguage"
                            >
                              <b-form-radio value="0">Name1</b-form-radio>
                              <b-form-radio value="1">Name2</b-form-radio>
                            </b-form-radio-group>
                          </b-form-group>
                        </el-col>
                        <el-col :span="6">
                          <b-form-group :label="$t('Max Dis.%')">
                            <b-form-input
                              placeholder="Max Discount Percentage"
                              v-model="cur_user.MaxDiscountRate"
                            ></b-form-input>
                          </b-form-group>
                        </el-col>
                        <el-col :span="6">
                          <b-form-group :label="$t('Max Dis.$')">
                            <b-form-input
                              placeholder="Max Discount Amount"
                              v-model="cur_user.MaxDiscountAmount"
                            ></b-form-input>
                          </b-form-group>
                        </el-col>
                      </el-row>
                      <el-row :gutter="10">
                        <!-- <el-col :span="24" v-if="false">
                          <b-form-group :label="$t('POS Setting')">
                            <b-form-checkbox-group
                              v-model="cur_user.RoleList"
                              inline="true"
                            >
                              <el-row :gutter="10">
                                <el-col :span="4" v-for="role in posrolelist" :key="role.IDn">
                                  <b-form-checkbox :value="role.Name">
                                    <span class="select_text">
                                      {{ role.ShowText }}
                                    </span>
                                  </b-form-checkbox>
                                </el-col>
                              </el-row>
                            </b-form-checkbox-group>
                          </b-form-group>
                        </el-col> -->
                        <!-- <el-col :span="24" v-if="false">
                          <b-form-group :label="$t('Web Setting')">
                            <b-form-checkbox-group
                              v-model="cur_user.RoleList"
                              inline="true"
                            >
                              <el-collapse>
                                <el-collapse-item
                                  class="collapseheader"
                                  v-for="group in rolelist"
                                  :key="group.FeatureGroup"
                                  :title="group.FeatureGroup"
                                  :name="group.FeatureGroup"
                                >
                                  <el-row :gutter="10">
                                    <el-col :span="4" v-for="role in group.Roles" :key="role.IDn">
                                      <b-form-checkbox :value="role.Name" @change="(checked) => userRole_Change(checked, role)">
                                        <span class="select_text">
                                          {{ role.ShowText1 }}
                                        </span>
                                        <el-tooltip class="item" effect="light" :content="role.Remarks" placement="top" v-if="role.Remarks">
                                          <span class="el-icon-info"></span>
                                        </el-tooltip>
                                      </b-form-checkbox>
                                    </el-col>
                                  </el-row>
                                </el-collapse-item>
                              </el-collapse>
                            </b-form-checkbox-group>
                          </b-form-group>
                        </el-col> -->
                      </el-row>
                      <el-row>
                        <el-col>
                          <b-button
                            squared
                            block
                            v-b-toggle.show_empolyee_info
                            variant="outline-info"
                            >{{ $t("Empolyee Information") }}</b-button
                          >
                        </el-col>
                      </el-row>
                      <b-collapse id="show_empolyee_info" v-model="showEmpolyeeInfo">
                        <b-row>
                          <b-col>
                            <b-row>
                              <b-col>
                                <base-input :label="$t('First Name')">
                                  <b-input
                                    placeholder="FirstName"
                                    class="form-control"
                                    v-model="cur_user.EmpolyeeInfo.FirstName"
                                  />
                                </base-input>
                              </b-col>
                              <b-col>
                                <base-input :label="$t('Last Name')">
                                  <b-input
                                    placeholder="LastName"
                                    class="form-control"
                                    v-model="cur_user.EmpolyeeInfo.LastName"
                                  />
                                </base-input>
                              </b-col>
                              <b-col>
                                <base-input :label="$t('Department')">
                                  <b-input
                                    placeholder="Department"
                                    class="form-control"
                                    v-model="cur_user.EmpolyeeInfo.Department"
                                  />
                                </base-input>
                              </b-col>
                              <b-col>
                                <base-input :label="$t('Position')">
                                  <b-form-select
                                    v-model="cur_user.EmpolyeeInfo.Position"
                                  >
                                    <b-form-select-option :value="null"
                                      >Please select an option</b-form-select-option
                                    >
                                    <b-form-select-option value="Owner"
                                      >Owner</b-form-select-option
                                    >
                                    <b-form-select-option value="Manager"
                                      >Manager</b-form-select-option
                                    >
                                    <b-form-select-option value="Sales"
                                      >Sales</b-form-select-option
                                    >
                                    <b-form-select-option value="Receiver"
                                      >Receiver</b-form-select-option
                                    >
                                    <b-form-select-option value="Service"
                                      >Customer Service</b-form-select-option
                                    >
                                  </b-form-select>
                                </base-input>
                              </b-col>
                            </b-row>

                            <b-row>
                              <b-col>
                                <base-input inline :label="$t('Tel')">
                                  <b-row>
                                    <b-col cols="8">
                                      <b-input
                                        placeholder="Tel."
                                        class="form-control"
                                        v-model="cur_user.EmpolyeeInfo.TEL"
                                      />
                                    </b-col>
                                    <b-col>
                                      <b-input
                                        placeholder="Ext."
                                        class="form-control"
                                        v-model="cur_user.EmpolyeeInfo.TELExt"
                                      />
                                    </b-col>
                                  </b-row>
                                </base-input>
                              </b-col>
                              <b-col>
                                <base-input inline :label="$t('Cel')">
                                  <b-input
                                    placeholder="Cel."
                                    class="form-control"
                                    v-model="cur_user.EmpolyeeInfo.Cell"
                                  />
                                </base-input>
                              </b-col>
                              <b-col>
                                <base-input inline :label="$t('Email')">
                                  <b-input
                                    placeholder="Email"
                                    class="form-control"
                                    v-model="cur_user.EmpolyeeInfo.EMail"
                                  />
                                </base-input>
                              </b-col>
                              <b-col>
                                <base-input inline :label="$t('Start Date')">
                                  <flat-pickr
                                    v-model="cur_user.EmpolyeeInfo.StartDate"
                                    :config="pickr_config"
                                    class="form-control"
                                    placeholder="Select Date"
                                  >
                                  </flat-pickr>
                                </base-input>
                              </b-col>
                            </b-row>

                            <b-row>
                              <b-col>
                                <base-input :label="$t('Address') + '1'">
                                  <b-input
                                    placeholder="Address 1"
                                    class="form-control"
                                    v-model="cur_user.EmpolyeeInfo.Address1"
                                  />
                                </base-input>
                              </b-col>
                              <b-col>
                                <base-input :label="$t('Address') + '2'">
                                  <b-input
                                    placeholder="Address 2"
                                    class="form-control"
                                    v-model="cur_user.EmpolyeeInfo.Address2"
                                  />
                                </base-input>
                              </b-col>
                              <b-col>
                                <base-input :label="$t('City')">
                                  <InputAutocomplete
                                    :items="citylist"
                                    :initval="cur_user.EmpolyeeInfo.City"
                                    @input="setinput_city"
                                  ></InputAutocomplete>
                                </base-input>
                              </b-col>
                              <b-col>
                                <base-input :label="$t('Post Code')">
                                  <b-input
                                    placeholder="Post Code"
                                    class="form-control"
                                    v-model="cur_user.EmpolyeeInfo.ZIP"
                                  />
                                </base-input>
                              </b-col>
                            </b-row>

                            <b-row>
                              <b-col>
                                <base-input :label="$t('Country')">
                                  <select
                                    class="form-control"
                                    v-model="cur_user.EmpolyeeInfo.CountryIDn"
                                  >
                                    <option
                                      v-for="(country, countryIDn) in countrylist"
                                      :key="countryIDn"
                                      :value="countryIDn"
                                      @change="changed_country"
                                    >
                                      {{ country.Country }}
                                    </option>
                                  </select>
                                </base-input>
                              </b-col>
                              <b-col>
                                <base-input :label="$t('Province')">
                                  <select
                                    class="form-control"
                                    v-model="cur_user.EmpolyeeInfo.Province"
                                  >
                                    <option
                                      v-for="(
                                        province, ProvinceIDn
                                      ) in cur_provincelist"
                                      :key="ProvinceIDn"
                                      :value="ProvinceIDn"
                                      @change="changed_province"
                                    >
                                      {{ province["Province"] }}
                                    </option>
                                  </select>
                                </base-input>
                              </b-col>
                              <b-col>
                                <base-input :label="$t('Birthday')">
                                  <flat-pickr
                                    v-model="cur_user.EmpolyeeInfo.BirthDay"
                                    :config="pickr_config"
                                    class="form-control"
                                    placeholder="Select birthday"
                                  >
                                  </flat-pickr>
                                </base-input>
                              </b-col>
                              <b-col>
                                <base-input :label="$t('Gender')">
                                  <b-form-select
                                    v-model="cur_user.EmpolyeeInfo.Gender"
                                  >
                                    <b-form-select-option :value="null"
                                      >Please select an option</b-form-select-option
                                    >
                                    <b-form-select-option value="Male"
                                      >Male</b-form-select-option
                                    >
                                    <b-form-select-option value="Female"
                                      >Female</b-form-select-option
                                    >
                                  </b-form-select>
                                </base-input>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col>
                                <base-input label="SIN">
                                  <b-input
                                    placeholder="Social Insurance Number"
                                    class="form-control"
                                    v-model="cur_user.EmpolyeeInfo.SIN"
                                  />
                                </base-input>
                              </b-col>
                              <b-col>
                                <base-input :label="$t('Wage')">
                                  <b-input
                                    placeholder="Wage"
                                    class="form-control"
                                    v-model="cur_user.EmpolyeeInfo.PayAmount"
                                  />
                                </base-input>
                              </b-col>
                              <b-col>
                                <base-input :label="$t('Remarks')">
                                  <b-input
                                    placeholder="Remarks"
                                    class="form-control"
                                    v-model="cur_user.EmpolyeeInfo.Remarks"
                                  />
                                </base-input>
                              </b-col>
                              <b-col> </b-col>
                            </b-row>
                            <b-row>
                              <b-col>
                                <base-input :label="$t('Contact')">
                                  <b-input
                                    placeholder="Contact"
                                    class="form-control"
                                    v-model="cur_user.EmpolyeeInfo.EmergencyContact"
                                  />
                                </base-input>
                              </b-col>
                              <b-col>
                                <base-input :label="$t('Tel')">
                                  <b-input
                                    placeholder="Tel."
                                    class="form-control"
                                    v-model="cur_user.EmpolyeeInfo.EmergencyTEL"
                                  />
                                </base-input>
                              </b-col>
                              <b-col>
                                <base-input :label="$t('Relationship')">
                                  <b-input
                                    placeholder="Relationship"
                                    class="form-control"
                                    v-model="
                                      cur_user.EmpolyeeInfo.EmergencyRelationship
                                    "
                                  />
                                </base-input>
                              </b-col>
                              <b-col> </b-col>
                            </b-row>

                            <b-row> </b-row>
                          </b-col>
                        </b-row>
                      </b-collapse>

                      <b-row class="justify-content-lg-center mt-4">
                        <b-col class="text-center" lg="4">
                          <el-button type="primary" @click="form_cancel">{{
                            $t("Back")
                          }}</el-button>
                        </b-col>
                        <b-col class="text-center" lg="4">
                          <el-button type="primary" @click="form_submit">{{
                            $t("Submit")
                          }}</el-button>
                        </b-col>
                      </b-row>
                    </b-form>
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>
          </b-card>
        </el-tab-pane>
        <el-tab-pane :label="$t('Setting Group')" name="Group">
          <b-card>
            <b-row v-if="grouplist">
              <b-col>
                <el-table
                  ref="group_table"
                  stripe
                  class="table-responsive table"
                  header-row-class-name="thead-light"
                  row-key="IDn"
                  :expand-row-keys="expandKeys"
                  :data="grouplist"
                  @expand-change="expandchange"
                  @row-click="group_row_clicked"
                >
                  <el-table-column type="expand" v-if="hasUtilityAuth('StaffEdit')">
                    <template slot-scope="scope">
                      <div v-if="cur_group_PosRoleList">
                        <el-row :gutter="10">
                          <el-col :span="2" class="text-right">
                            {{ $t('Name') + ' : ' }}
                          </el-col>
                          <el-col :span="4" class="text-right">
                            <el-input size="mini" v-model="cur_group.Name"></el-input>
                          </el-col>
                          <el-col :span="2" class="text-right">
                            {{ $t('Max Dis.%') + ' : ' }}
                          </el-col>
                          <el-col :span="4">
                            <el-input size="mini" v-model="cur_group.MaxDiscountRate"></el-input>
                          </el-col>
                          <el-col :span="2" class="text-right">
                            {{ $t('Max Dis.$') + ' : ' }}
                          </el-col>
                          <el-col :span="4">
                            <el-input size="mini" v-model="cur_group.MaxDiscountAmount"></el-input>
                          </el-col>
                          <el-col :span="2" class="text-right">
                            {{ $t('Remarks') + ' : ' }}
                          </el-col>
                          <el-col :span="4">
                            <el-input size="mini" v-model="cur_group.Remarks"></el-input>
                          </el-col>
                        </el-row>
                        <b-card
                          :title="$t('POS Setting Group')"
                          style="margin-top: 10px;"
                        >
                          <!-- <el-row :gutter="10">
                            <el-col :span="24">
                              <div class="group_title">{{ $t('POS Setting Group') }}</div>
                            </el-col>
                          </el-row> -->
                          <b-form-checkbox-group
                            v-model="cur_group_PosRoleList"
                            inline="true"
                          >
                            <el-row :gutter="10">
                              <el-col :span="4" v-for="role in posrolelist" :key="role.IDn">
                                <b-form-checkbox :value="role.Name" >
                                  <span class="select_text">
                                    {{ role.ShowText }}
                                  </span>
                                </b-form-checkbox>
                              </el-col>
                            </el-row>
                          </b-form-checkbox-group>
                        </b-card>
                      </div>
                      <b-card
                        v-if="cur_group_RoleList"
                        :title="$t('Web Setting Group')"
                        style="margin-top: 10px;"
                      >
                        <!-- <el-row :gutter="10">
                          <el-col :span="24">
                            <div class="group_title">{{ $t('Web Setting Group') }}</div>
                          </el-col>
                        </el-row> -->
                        <b-form-checkbox-group
                          v-model="cur_group_RoleList"
                          inline="true"
                        >
                          <el-collapse>
                            <el-collapse-item
                              class="collapseheader"
                              v-for="group in rolelist"
                              :key="group.FeatureGroup"
                              :title="group.FeatureGroup"
                              :name="group.FeatureGroup"
                            >
                              <el-row :gutter="10">
                                <el-col :span="4" v-for="role in group.Roles" :key="role.IDn">
                                  <b-form-checkbox :value="role.Name" @change="(checked) => groupRole_Change(checked, role)">
                                    <span class="select_text" @dblclick="(evt) => editRoleRelate(evt, role)">
                                      {{ role.ShowText1 }}
                                    </span>
                                    <el-tooltip class="item" effect="light" :content="role.Remarks" placement="top" v-if="role.Remarks">
                                      <span class="el-icon-info"></span>
                                    </el-tooltip>
                                  </b-form-checkbox>
                                </el-col>
                              </el-row>
                            </el-collapse-item>
                          </el-collapse>
                        </b-form-checkbox-group>
                      </b-card>
                      <el-row :gutter="10" class="mt-2 mb-2">
                        <el-col :span="6" :offset="12">
                          <el-button type="primary" size="mini" @click="cancel_group(scope.row)">{{
                            $t("Cancel")
                          }}</el-button>
                        </el-col>
                        <el-col :span="6">
                          <el-button type="primary" size="mini" @click="submit_group('update',scope.row)">{{
                            $t("Update")
                          }}</el-button>
                        </el-col>
                      </el-row>
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('Name')" prop="Name">
                    <template v-slot="{ row }">
                      {{ row.Name }}
                    </template>
                  </el-table-column>
                  <el-table-column label="" prop="Remarks">
                    <template slot="header">
                      {{ $t('Remarks') }}
                      <i @click="add_group" class="el-icon-circle-plus float-right mt-1 mr-6" style="color: #409eff" v-acl:StaffEdit.enable.show></i>
                    </template>
                  </el-table-column>
                </el-table>
              </b-col>
            </b-row>
          </b-card>
        </el-tab-pane>
      </el-tabs>
      <b-row class="mt-4">
        <b-col> </b-col>
      </b-row>
    </b-container>
    <el-dialog
      title="Tips"
      :visible.sync="groupDialog">
      <div v-if="cur_group_PosRoleList">
        <el-row :gutter="10">
          <el-col :span="2" class="text-right">
            {{ $t('Name') + ' : ' }}
          </el-col>
          <el-col :span="4" class="text-right">
            <el-input size="mini" v-model="cur_group.Name"></el-input>
          </el-col>
          <el-col :span="2" class="text-right">
            {{ $t('Remarks') + ' : ' }}
          </el-col>
          <el-col :span="16">
            <el-input size="mini" v-model="cur_group.Remarks"></el-input>
          </el-col>
        </el-row>
        <b-card
          :title="$t('POS Setting Group')"
          style="margin-top: 10px;"
        >
          <!-- <el-row :gutter="10">
            <el-col :span="24">
              <div class="group_title">{{ $t('POS Setting Group') }}</div>
            </el-col>
          </el-row> -->
          <b-form-checkbox-group
            v-model="cur_group_PosRoleList"
            inline="true"
          >
            <el-row :gutter="10">
              <el-col :span="4" v-for="role in posrolelist" :key="role.IDn">
                <b-form-checkbox :value="role.Name" >
                  <span class="select_text">
                    {{ role.ShowText }}
                  </span>
                </b-form-checkbox>
              </el-col>
            </el-row>
          </b-form-checkbox-group>
        </b-card>
      </div>
      <b-card
        v-if="cur_group_RoleList"
        :title="$t('Web Setting Group')"
        style="margin-top: 10px;"
      >
        <!-- <el-row :gutter="10">
          <el-col :span="24">
            <div class="group_title">{{ $t('Web Setting Group') }}</div>
          </el-col>
        </el-row> -->
        <b-form-checkbox-group
          v-model="cur_group_RoleList"
          inline="true"
        >
          <el-collapse>
            <el-collapse-item
              class="collapseheader"
              v-for="group in rolelist"
              :key="group.FeatureGroup"
              :title="group.FeatureGroup"
              :name="group.FeatureGroup"
            >
              <el-row :gutter="10">
                <el-col :span="4" v-for="role in group.Roles" :key="role.IDn">
                  <b-form-checkbox :value="role.Name" @change="(checked) => groupRole_Change(checked, role)">
                    <span class="select_text">
                      {{ role.ShowText1 }}
                    </span>
                    <el-tooltip class="item" effect="light" :content="role.Remarks" placement="top" v-if="role.Remarks">
                      <span class="el-icon-info"></span>
                    </el-tooltip>
                  </b-form-checkbox>
                </el-col>
              </el-row>
            </el-collapse-item>
          </el-collapse>
        </b-form-checkbox-group>
      </b-card>
      <span slot="footer" class="dialog-footer">
        <el-button @click="groupDialog = false">{{ $t("Cancel") }}</el-button>
        <el-button type="primary" @click="submit_group('add', null)">{{ $t("Add") }}</el-button>
      </span>
    </el-dialog>
    
    <el-dialog
      :title="$t('Related Role')"
      :visible.sync="relateDialog">
      <el-row :gutter="10">
        <el-col :span="6">
          <b-form-group :label="$t('Name')">
            <!-- <div v-if="cur_role"><span>{{ cur_role.Name ? cur_role.Name : '' }}</span></div> -->
            <b-form-input
              v-if="cur_role"
              v-model="cur_role.Name"
              disabled
            ></b-form-input>
          </b-form-group>
        </el-col>
        <el-col :span="6">
          <b-form-group :label="$t('Related Type')">
            <select
              class="form-control"
              v-model="cur_relate"
              @change="changeRelate"
            >
              <option value="RequiredRole">
                {{ "Required Role" }}
              </option>
              <option value="DisableRole">
                {{ "Disable Role" }}
              </option>
            </select>
          </b-form-group>
        </el-col>
        <el-col :span="6">
          <b-form-group :label="$t('Remarks')">
            <b-form-input
              v-if="cur_role"
              v-model="cur_role.Remarks"
            ></b-form-input>
          </b-form-group>
        </el-col>
      </el-row>
      <div v-if="cur_relate_RoleList">
        <b-form-checkbox-group
          v-model="cur_relate_RoleList"
          inline="true"
        >
          <el-collapse>
            <el-collapse-item
              class="collapseheader"
              v-for="group in rolelist_full"
              :key="group.FeatureGroup"
              :title="group.FeatureGroup"
              :name="group.FeatureGroup"
            >
              <el-row :gutter="10">
                <el-col :span="4" v-for="role in group.Roles" :key="role.IDn">
                  <b-form-checkbox :value="role.Name">
                    <span class="select_text">
                      {{ role.ShowText1 }}
                    </span>
                    <el-tooltip class="item" effect="light" :content="role.Remarks" placement="top" v-if="role.Remarks">
                      <span class="el-icon-info"></span>
                    </el-tooltip>
                  </b-form-checkbox>
                </el-col>
              </el-row>
            </el-collapse-item>
          </el-collapse>
        </b-form-checkbox-group>
      </div>
      <el-row :gutter="10" class="mt-2 mb-2">
        <el-col :span="6" :offset="12">
          <el-button type="primary" size="mini" @click="cancel_relate">{{
            $t("Cancel")
          }}</el-button>
        </el-col>
        <el-col :span="6">
          <el-button type="primary" size="mini" @click="submit_relate">{{
            $t("Update")
          }}</el-button>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>
<script>
import { Table, TableColumn, Tabs, Row, Col, TabPane, Dialog, Button, Tooltip, Collapse, CollapseItem, Card } from "element-ui";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { getStore, setStore } from "../../util/helper";
import InputAutocomplete from "../InputAutocomplete";
import { isSuper, hasUtilityAuth } from "../../directives/acl";

const axios = require("axios");

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Tabs.name]: Tabs,
    [Row.name]: Row,
    [Col.name]: Col,
    [TabPane.name]: TabPane,
    [Dialog.name]: Dialog,
    [Button.name]: Button,
    [Tooltip.name]: Tooltip,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Card.name]: Card,
    [flatPicker.name]: flatPicker,
    InputAutocomplete,
  },
  data() {
    return {
      tab_name: "Staff",
      currpage: "list",
      cur_user: null,
      cur_opt: null,
      showEmpolyeeInfo: false,
      new_user: {
        IDn: 0,
        UserName: "",
        Password: "",
        EmployeeIDn: 0,
        UserRole: "Staff", // Owner, Manager, Staff
        isLimitedUser: "POS",
        UILanguage: 0, // 0: English; 1: 简体; 2: 繁体
        ProductLanguage: 0, // 0: Name1; 1: Name2
        MaxDiscountAmount: 0,
        MaxDiscountRate: 0,
        Store: [],
        RoleList: [],
        EmpolyeeInfo: {
          IDn: 0,
          Department: "",
          FirstName: "",
          LastName: "",
          Position: "",
          Gender: "",
          BirthDay: null,
          SIN: "",
          TEL: "",
          TELExt: "",
          Cell: "",
          EMail: "",
          Address1: "",
          Address2: "",
          City: "",
          Province: "",
          Country: "",
          ZIP: "",
          EmergencyContact: "",
          EmergencyTEL: "",
          EmergencyRelationship: "",
          StartDate: null,
          Language: "",
          PayPeriod: "",
          PayAmount: "",
          CreateDateTime: "",
          CreateBy: "",
          UpdateDateTime: "",
          UpdateBy: "",
          Remarks: "",
          Status: "",
        },
      },
      pickr_config: {
        enableTime: false,
        noCalendar: false,
        dateFormat: "Y-m-d",
        // time_24hr: true
      },

      userlist: null,
      locationlist: null,
      locationIDs: [],
      rolelist: null,
      rolelist_list: null,
      rolelist_full: null,
      posrolelist: null,
      onlyself: null,

      expandKeys: [],
      grouplist: null,
      groupDialog: false,
      relateDialog: false,
      new_group: {
        IDn: 0,
        Name: "",
        Remarks: ""
      },
      cur_group: null,
      cur_group_PosRoleList: null,
      cur_group_RoleList: null,
      cur_role: null,
      cur_relate: "",
      cur_relate_RoleList: null,

      countrylist: null,
      provincelist: null,
      citylist: [],
      cur_provincelist: null,

      pwgerenate: null,
      pw_length: 3,

      token: "",
      user_id: 0,

      dismessage: "",
      countdown: 0,
      variant: "success",
    };
  },
  methods: {
    hasUtilityAuth,
    editRoleRelate(evt, role) {
      // console.log('editRoleRelate', role.Name);
      if (!isSuper()) return;
      this.cur_role = role;
      this.cur_relate = 'RequiredRole';
      this.cur_relate_RoleList = [];
      try {
        this.cur_relate_RoleList = JSON.parse(role.RequiredRole);
      } catch {}
      this.relateDialog = true;
    },
    changeRelate() {
      // console.log('changeRelate', this.cur_relate);
      if (this.cur_relate == 'RequiredRole') {
        this.cur_role.DisableRole = JSON.stringify(this.cur_relate_RoleList);
      } else if (this.cur_relate == 'DisableRole') {
        this.cur_role.RequiredRole = JSON.stringify(this.cur_relate_RoleList);
      }
      this.cur_relate_RoleList = [];
      try {
        this.cur_relate_RoleList = JSON.parse(this.cur_role[this.cur_relate]);
      } catch {}
    },
    cancel_relate() {
      // console.log('cancel_relate';
      this.cur_role = null;
      this.cur_relate = '';
      this.cur_relate_RoleList = null;
      this.relateDialog = false;
    },
    submit_relate() {
      // console.log('submit_relate');
      if (!this.cur_relate_RoleList) {
        this.cur_relate_RoleList = [];
      }
      this.cur_role[this.cur_relate] = JSON.stringify(this.cur_relate_RoleList);
      this.submit_role(this.cur_role);
      this.cur_role = null;
      this.cur_relate = '';
      this.cur_relate_RoleList = null;
      this.relateDialog = false;
    },
    async submit_role(role) {
      if (!role) alert('Parameter role is not valid.');
      
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);

      for (var key in role) {
        bodyFormData.append(key, role[key]);
      }
      
      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Staff/role_edit",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.rolelist = rt.data.roles;
              that.rolelist_full = rt.data.roles_full;
              that.rolelist_list = {};
              for (let i = 0; i < that.rolelist_full.length; i++) {
                for (let j = 0; j < that.rolelist_full[i].Roles.length; j++) {
                  that.rolelist_list[that.rolelist_full[i].Roles[j].Name] = that.rolelist_full[i].Roles[j];
                }
              }
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    groupRole_Change(checked, role) {
      // console.log('groupRole_Change', JSON.parse(JSON.stringify(this.cur_group_RoleList)), checked.length, role);
      let change_sub = true;
      if (checked.includes(role.Name)) {
        let required = [];
        try {
          required = JSON.parse(role.RequiredRole);
        } catch {}
        for (let i = 0; i < required.length; i++) {
          if (!this.cur_group_RoleList.includes(required[i])) {
            this.cur_group_RoleList.push(required[i]);
            if (change_sub) {
              // console.log('test_1', required[i], this.rolelist_list, this.rolelist_list[required[i]]);
              this.groupRole_Change(this.cur_group_RoleList, this.rolelist_list[required[i]]);
            }
          }
        }
      } else {
        let disable = [];
        try {
          disable = JSON.parse(role.DisableRole);
        } catch {}
        for (let i = 0; i < disable.length; i++) {
          if (this.cur_group_RoleList.includes(disable[i])) {
            let index = this.cur_group_RoleList.indexOf(disable[i]);
            this.cur_group_RoleList.splice(index, 1);
            if (change_sub) {
              // console.log('test_2', disable[i], this.rolelist_list, this.rolelist_list[disable[i]]);
              this.groupRole_Change(this.cur_group_RoleList, this.rolelist_list[disable[i]]);
            }
          }
        }
      }
      // console.log('groupRole_Change_rslt', this.cur_group_RoleList);
    },
    userRole_Change(checked, role) {
      // console.log('userRole_Change', checked.length, role);
      // this.cur_user.RoleList = ['StoreMenu'];
      if (checked.includes(role.Name)) {
        let required = [];
        try {
          required = JSON.parse(role.RequiredRole);
        } catch {}
        // console.log('RequiredRole', role.RequiredRole, required.length);
        for (let i = 0; i < required.length; i++) {
          if (!this.cur_user.RoleList.includes(required[i])) this.cur_user.RoleList.push(required[i]);
        }
      } else {
        let disable = [];
        try {
          disable = JSON.parse(role.DisableRole);
        } catch {}
        // console.log('DisableRole', role.DisableRole, disable.length);
        for (let i = 0; i < disable.length; i++) {
          if (this.cur_user.RoleList.includes(disable[i])) {
            let index = this.cur_user.RoleList.indexOf(disable[i]);
            this.cur_user.RoleList.splice(index, i);
          }
        }
      }
      // console.log('userRole_Change_rslt', this.cur_user.RoleList);
    },
    add_group() {
      this.cur_group = JSON.parse(JSON.stringify(this.new_group));
      this.cur_group_PosRoleList = [];
      this.cur_group_RoleList = [];
      this.groupDialog = true;
    },
    cancel_group(row) {
      this.$refs.group_table.toggleRowExpansion(row);

      this.cur_group = null;
      this.cur_group_PosRoleList = null;
      this.cur_group_RoleList = null;
    },
    submit_group(opt, row) {
      if (row) {
        this.$refs.group_table.toggleRowExpansion(row);
      }
      this.groupDialog = false;

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("opt", opt);
      for (var key in this.cur_group) {
        bodyFormData.append(key, this.cur_group[key]);
      }
      bodyFormData.append("RoleList", JSON.stringify(this.cur_group_RoleList));
      bodyFormData.append("PosRoleList", JSON.stringify(this.cur_group_PosRoleList));

      this.grouplist = null;
      var that = this;

      axios({
        method: "post",
        url: "/Api/Web/Staff/group_edit",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.grouplist = rt.data.groups;
              that.cur_group = null;
              that.cur_group_PosRoleList = null;
              that.cur_group_RoleList = null;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
      this.groupDialog = false;
    },
    group_row_clicked(row, column, event) {
      // console.log('group_row_clicked', row, column, event);
      if (!hasUtilityAuth('StaffEdit')) return;
      this.$refs.group_table.toggleRowExpansion(row);
    },
    expandchange(row, para) {
      if (this.expandKeys.indexOf(row.IDn) >= 0) {
        this.expandKeys.shift();
        return;
      }

      this.cur_group = JSON.parse(JSON.stringify(row));

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("opt", "get");
      for (var key in this.cur_group) {
        bodyFormData.append(key, this.cur_group[key]);
      }
      var that = this;

      axios({
        method: "post",
        url: "/Api/Web/Staff/group_edit",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.cur_group_PosRoleList = rt.data.PosRoleList,
              that.cur_group_RoleList = rt.data.RoleList,
              that.expandKeys.shift();
              that.expandKeys.push(row.IDn);
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
      if (this.expandKeys.indexOf(row.IDn) >= 0) {
        this.expandKeys.shift();
      }
    },
    table_change(tab, event) {
      if (tab.index == 0) {
        this.get_user_list();
      }
      this.currpage = "list";
    },
    changed_roles() {
      // if (this.cur_opt != "add") {
      //   return;
      // }
      for (var i = 0; i < this.grouplist.length; i++) {
        if (this.grouplist[i].Name == this.cur_user.UserRole) {
          this.cur_user.MaxDiscountRate = this.grouplist[i].MaxDiscountRate;
          this.cur_user.MaxDiscountAmount = this.grouplist[i].MaxDiscountAmount;
          break;
        }
      }

      this.cur_user.RoleList = [];
      
      // POS
      for (var i = 0; i < this.posrolelist.length; i++) {
        if (
          this.posrolelist[i].DefaultApply.indexOf(this.cur_user.UserRole) > 0
        ) {
          this.cur_user.RoleList.push(this.posrolelist[i].Name);
        }
      }

      // Web
      for (var i = 0; i < this.rolelist_full.length; i++) {
        for (var j = 0; j < this.rolelist_full[i].Roles.length; j++) {
          if (
            this.rolelist_full[i].Roles[j].DefaultApply.indexOf(this.cur_user.UserRole) > 0
          ) {
            this.cur_user.RoleList.push(this.rolelist_full[i].Roles[j].Name);
          }
        }
      }
    },
    add_user() {
      if (!hasUtilityAuth('StaffEdit')) return;
      this.currpage = "edit";
      this.cur_user = JSON.parse(JSON.stringify(this.new_user));
      this.cur_opt = "add";
      this.changed_roles();
      this.changed_country();
    },
    row_clicked_event(row, column, event) {
      // console.log("row_clicked_event", row, column, event);
      if (!hasUtilityAuth('StaffEdit')) return;
      if (column && column.label == this.$t("Status")) {
        return;
      }
      this.currpage = "edit";
      this.cur_user = JSON.parse(JSON.stringify(row));
      this.cur_user.Password = "";
      if (row.RoleList) {
        this.cur_user.RoleList = [];
        try {
          this.cur_user.RoleList = JSON.parse(row.RoleList);
        } catch {}
      } else {
        this.cur_user.RoleList = [];
      }
      if (!this.cur_user.EmpolyeeInfo || this.cur_user.EmpolyeeInfo == "null") {
        this.cur_user.EmpolyeeInfo = JSON.parse(
          JSON.stringify(this.new_user.EmpolyeeInfo)
        );
      }
      if (row.UserRole == "Owner") {
        if (row.isLimitedUser == "POS") {
          this.cur_user.RoleList = [];
          for (var i = 0; i < this.posrolelist.length; i++) {
            this.cur_user.RoleList.push(this.posrolelist[i].Name);
          }
        } else {
          this.cur_user.RoleList = [];
          for (var i = 0; i < this.rolelist.length; i++) {
            this.cur_user.RoleList.push(this.rolelist[i].Name);
          }
        }
      }
      this.changed_country();
      this.cur_opt = "update";
    },
    form_cancel() {
      this.currpage = "list";
    },
    setinput_city(city) {
      this.cur_user.EmpolyeeInfo.City = city;
    },
    changed_province() {
      if (
        !this.cur_user.EmpolyeeInfo.ProvinceIDn ||
        this.cur_user.EmpolyeeInfo.ProvinceIDn == undefined ||
        this.cur_user.EmpolyeeInfo.ProvinceIDn <= 0
      ) {
        return;
      }

      this.cur_user.EmpolyeeInfo.Province =
        this.provincelist[this.cur_user.EmpolyeeInfo.ProvinceIDn].Province;
    },
    changed_country() {
      if (
        !this.cur_user.EmpolyeeInfo.CountryIDn ||
        this.cur_user.EmpolyeeInfo.CountryIDn == undefined ||
        this.cur_user.EmpolyeeInfo.CountryIDn <= 0
      ) {
        this.cur_provincelist = this.provincelist;
        return;
      }

      this.cur_user.EmpolyeeInfo.Country =
        this.countrylist[this.cur_user.EmpolyeeInfo.CountryIDn].Country;

      this.cur_provincelist = {};
      for (var idx in this.provincelist) {
        if (
          this.provincelist[idx].CountryIDn ==
          this.cur_user.EmpolyeeInfo.CountryIDn
        ) {
          this.cur_provincelist[idx] = this.provincelist[idx];
        }
      }
    },
    async change_row_property(row, attr) {
      // console.log("change_row_property", row, attr);
      if (!hasUtilityAuth('StaffEdit')) return;
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("opt", "update");
      bodyFormData.append("IDn", row["IDn"]);
      bodyFormData.append(attr, row[attr]);
      bodyFormData.append("UpdateBy", this.user_id);
      var that = this;

      axios({
        method: "post",
        url: "/Api/Web/Staff/edit",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.dismessage = that.$t("Data Updated Successfully");
              that.countdown = 2;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async gerenate_pw(event) {
      if (event) {
        event.preventDefault();
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("pw_length", this.pw_length);

      var that = this;

      axios({
        method: "post",
        url: "/Api/Web/Staff/gerenate_pw",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.cur_user.Password = rt.data.password;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async form_submit(event) {
      if (event) {
        event.preventDefault();
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("opt", this.cur_opt);
      var that = this;
      for (var key in this.cur_user) {
        if (key == "RoleList" || key == "EmpolyeeInfo") {
          if (this.cur_user[key]) {
            bodyFormData.append(key, JSON.stringify(this.cur_user[key]));
          } else {
            bodyFormData.append(key, "[]");
          }
        } else {
          if (this.cur_user[key]) bodyFormData.append(key, this.cur_user[key]);
        }
      }

      axios({
        method: "post",
        url: "/Api/Web/Staff/edit",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              //that.$router.push("/store");
              that.$router.go();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async get_user_list() {
      // this will be called only after form is valid. You can do api call here to login
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Staff",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.userlist = rt.data.users;
              that.grouplist = rt.data.groups;
              that.locationlist = rt.data.locations;
              that.countrylist = rt.data.countrys;
              that.provincelist = rt.data.provinces;
              that.citylist = rt.data.cities;
              that.locationIDs = [];
              for (var key in that.locationlist) {
                that.locationIDs.push({
                  html:
                    '<span class="select_text">' +
                    that.locationlist[key].LocationName +
                    " (" +
                    that.locationlist[key].LocationID +
                    ")</span>",
                  value: that.locationlist[key].LocationID,
                });
              }
              that.rolelist = rt.data.roles;
              that.rolelist_full = rt.data.roles_full;
              that.rolelist_list = {};
              for (let i = 0; i < that.rolelist_full.length; i++) {
                for (let j = 0; j < that.rolelist_full[i].Roles.length; j++) {
                  that.rolelist_list[that.rolelist_full[i].Roles[j].Name] = that.rolelist_full[i].Roles[j];
                }
              }
              that.posrolelist = rt.data.posroles; //XXXXXXXXXXXXX

              that.onlyself = rt.data.onlyself;
              if (that.onlyself) {
                that.currpage = "edit";
                that.cur_user = that.userlist[0];
                that.cur_user.Password = "";
                that.cur_user.RoleList = []
                try {
                  that.cur_user.RoleList = JSON.parse(that.userlist[0].RoleList);
                } catch {}
                that.cur_opt = "update";
              }
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    close_alert(countDownChanged) {
      this.countdown = countDownChanged;
      if (this.countdown == 0) {
        this.dismessage = "";
        this.variant = "success";
      }
    },
    async init_data() {
      this.token = getStore("token");
      this.user_id = getStore("user_id");
      if (!this.token || !this.user_id) {
        this.$router.push("/login");
        return;
      }
      return this.get_user_list();
    },
  },
  mounted() {
    this.init_data();
    this.windowHeight = window.innerHeight;
  },
};
</script>
<style>
.content {
  padding-top: 80px;
}
.search-button {
  margin-top: 2rem;
}
.select_text {
  margin-left: -1rem;
}
.staff .card .table th {
  padding: 0rem 0.5rem;
}
.staff .card .table td {
  padding: 0rem 0.5rem;
}
.staff .el-tabs__header {
  margin: 0;
}
.has-label {
  display: block;
}
.numinput_length {
  max-width: 5rem;
}
.group_title {
  padding-top: 0.5 rem;
  font-weight: 600;
  text-decoration: underline;
}
.group_text {
  padding-top: 0.5 rem;
  font-weight: 600;
}
.el-collapse-item__header {
  background-color: #a5d1fa;
  font-size: 1.3rem;
  padding-left: 2rem;
  padding-right: 2rem;
  height: 2.2rem;
  border-radius: 0.75rem 0.75rem 0 0;
}
.el-collapse-item__content {
  padding-bottom: 0.5rem;
  border: 3px solid rgb(165, 209, 250);
}
.el-collapse-item__wrap {
  display: contents;
  content: "";
  clear: both;
  width: calc(100% - 5px);
  overflow-y: visible;
}
</style>
